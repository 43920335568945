import { Component } from '@angular/core';
import { DarkModeService } from 'src/services/dark-mode.service';

@Component({
  selector: 'app-dark-mode-toggle',
  templateUrl: './dark-mode-toggle.component.html',
  styleUrls: ['./dark-mode-toggle.component.scss'],
})
export class DarkModeToggleComponent {
  constructor(protected darkModeService: DarkModeService) {}
}
