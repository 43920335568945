import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppFooterComponent } from './app-footer.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconsModule } from 'src/components/app/app-footer/icons/icons.module';

@NgModule({
  declarations: [AppFooterComponent],
  imports: [CommonModule, BrowserModule, RouterModule, IconsModule, NgOptimizedImage],
  exports: [AppFooterComponent],
})
export class AppFooterModule {}
