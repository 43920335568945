import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChangelogDto } from 'src/dto/ChangelogDto';

@Injectable({
  providedIn: 'root',
})
export class ChangelogService {
  constructor(private httpClient: HttpClient) {}

  public getChangelog(): Observable<ChangelogDto[]> {
    return this.httpClient.get<ChangelogDto[]>(`${environment.baseUrl}/api/changelog`);
  }
}
