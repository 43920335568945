import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueueTypeDto } from 'src/dto/QueueTypeDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class QueueTypeService {
  constructor(private httpClient: HttpClient) {}

  public getQueueTypes(): Observable<QueueTypeDto[]> {
    return this.httpClient.get<QueueTypeDto[]>(`${environment.baseUrl}/api/queue-types`);
  }
}
