import { Component, OnDestroy, OnInit } from '@angular/core';
import { DarkModeService } from 'src/services/dark-mode.service';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  protected isScrolled = false;

  constructor(protected darkModeService: DarkModeService) {}

  ngOnInit(): void {
    document.addEventListener('scroll', this.onScroll.bind(this));
  }

  ngOnDestroy(): void {
    document.removeEventListener('scroll', this.onScroll.bind(this));
  }

  private onScroll(): void {
    this.isScrolled = window.scrollY > 0;
  }
}
