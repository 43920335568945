import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SpinnerComponent } from 'src/components/shared/spinner/spinner.component';
import { SimpleDialogComponent } from 'src/components/shared/simple-dialog/simple-dialog.component';

@NgModule({
  imports: [CommonModule, BrowserModule],
  declarations: [SpinnerComponent, SimpleDialogComponent],
  exports: [SpinnerComponent, SimpleDialogComponent],
})
export class SharedModule {}
