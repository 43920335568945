<app-header />

<main>
  <router-outlet />
</main>

<app-dark-mode-toggle />

<app-footer />

<app-snowflakes />
