<div
  [class]="'match-summary ' + this.getMatchStatusClass() + (match._tmp.detailsTabActive ? ' without-border-radius-bottom' : '')">
  <div class="game-info">
    <span class="game-type">{{ match.queueType.displayName }}</span>
    <span class="time-ago"
          [pTooltip]="(match.gameEnd | date:'E. d MMM y, H:mm:ss') ?? ''"
          tooltipPosition="bottom"
          tooltipStyleClass="p-tooltip-wide">
      {{ match.timeAgo }}
    </span>

    <span class="game-duration">{{ this.getMatchDuration() }}</span>
    <span [class]="'game-result ' + this.getMatchStatusClass()">{{ this.getMatchStatusClass() }}</span>
  </div>

  <div class="champion-info">
    <div class="summoner" *ngFor="let participant of matchHelpers.getLookbackParticipants(currentLookback, match)">
      <span class="title">{{ participant.riotIdName }}</span>

      <figure class="champion-icon">
        <img [ngSrc]="participant.champion.imageUrl" [alt]="participant.champion.name" height="85" width="85">

        <figcaption>{{ participant.champLevel }}</figcaption>
      </figure>

      <div class="summoner-spells">
        <img [ngSrc]="participant.summonerSpell1.imageUrl"
             [alt]="participant.summonerSpell1.summonerId + ' summoner spell image'"
             [pTooltip]='participant.summonerSpell1.name'
             tooltipPosition="top"
             width="40"
             height="40"/>
        <img [ngSrc]="participant.summonerSpell2.imageUrl"
             [alt]="participant.summonerSpell2.summonerId + ' summoner spell image'"
             [pTooltip]='participant.summonerSpell2.name'
             tooltipPosition="top"
             width="40"
             height="40"/>
      </div>

      <div class="stats">
        <div class="kda">
          <span class="kills">{{ participant.kills }}</span>
          <span class="deaths">{{ participant.deaths }}</span>
          <span class="assists">{{ participant.assists }}</span>
        </div>

        <span class="kda-ratio">{{ participant.kda }} KDA</span>
        <span class="cs-count">{{ participant.farm }} CS ({{ participant.farmPerMinute }})</span>
      </div>

      <ul class="items">
        <li *ngFor="let item of matchHelpers.getParticipantItems(participant)">
          <img *ngIf="item.imageUrl"
               [ngSrc]="item.imageUrl"
               class="item"
               [alt]="item.id + ' item image'"
               [pTooltip]='item.name'
               tooltipPosition="top"
               width="40"
               height="40"/>

          <span *ngIf="!item.imageUrl" class="empty-slot"></span>
        </li>
        <li><span class="filler"></span></li>
      </ul>
    </div>
  </div>

  <div class="participants">
    <ul class="team-list blue">
      <span class="title">Participants</span>

      <li *ngFor="let participant of matchHelpers.getBlueTeamParticipants(match)"
          [class]="matchHelpers.isLookbackParticipant(currentLookback, participant) ? 'active-summoner' : ''">
        <figure>
          <img [ngSrc]="participant.champion.imageUrl" [alt]="participant.champion.name" height="30" width="30">

          <figcaption [pTooltip]="this.matchHelpers.getParticipantRiotId(participant)"
                      tooltipPosition="top"
                      (click)="openRequestDialog($event, participant)">
            {{ participant.riotIdName }}
          </figcaption>
        </figure>
      </li>
    </ul>
    <ul class="team-list red">
      <li *ngFor="let participant of matchHelpers.getRedTeamParticipants(match)"
          [class]="matchHelpers.isLookbackParticipant(currentLookback, participant) ? 'active-summoner' : ''">
        <figure>
          <img [ngSrc]="participant.champion.imageUrl" [alt]="participant.champion.name" height="30" width="30">

          <figcaption [pTooltip]="this.matchHelpers.getParticipantRiotId(participant)"
                      tooltipPosition="top"
                      (click)="openRequestDialog($event, participant)">
            {{ participant.riotIdName }}
          </figcaption>
        </figure>
      </li>
    </ul>
  </div>

  <div class="expand-container" (click)="match._tmp.detailsTabActive = !(match._tmp.detailsTabActive ?? false)">
    <i *ngIf="!(match._tmp.detailsTabActive ?? false)" class="bx bx-chevron-down"></i>
    <i *ngIf="match._tmp.detailsTabActive ?? false" class="bx bx-chevron-up"></i>
  </div>
</div>

<app-lookback-match-details *ngIf="match._tmp.detailsTabActive ?? false"
                            [currentLookback]="currentLookback"
                            [match]="match"/>
