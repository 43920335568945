import { Component, OnInit } from '@angular/core';
import { LookbackRequestDto } from 'src/dto/LookbackRequestDto';
import { LookbackRequestSummonerDto } from 'src/dto/LookbackRequestSummonerDto';

@Component({
  selector: 'app-home',
  templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
  protected lookbackRequest: LookbackRequestDto = new LookbackRequestDto();

  public ngOnInit(): void {
    this.lookbackRequest.summoners = [new LookbackRequestSummonerDto(), new LookbackRequestSummonerDto()];
  }
}
