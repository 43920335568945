import { Component, Input, OnInit } from '@angular/core';
import { LookbackDto } from 'src/dto/LookbackDto';
import { MatchDto } from 'src/dto/MatchDto';
import { MatchParticipantDto } from 'src/dto/MatchParticipantDto';
import { MatchHelpers } from 'src/helpers/match.helpers';
import Team from 'src/dto/Team';
import { DarkModeService } from 'src/services/dark-mode.service';

@Component({
  selector: 'app-lookback-match-details-scoreboard',
  templateUrl: './lookback-match-details-scoreboard.component.html',
  styleUrls: ['./lookback-match-details-scoreboard.component.scss'],
  providers: [MatchHelpers],
})
export class LookbackMatchDetailsScoreboardComponent implements OnInit {
  @Input({ required: true })
  public currentLookback: LookbackDto;
  @Input({ required: true })
  public match: MatchDto;
  @Input({ required: true })
  public participants: MatchParticipantDto[];
  @Input({ required: true })
  public team: Team;

  protected wonGame: boolean;

  constructor(protected matchHelpers: MatchHelpers, protected darkModeService: DarkModeService) {}

  public ngOnInit(): void {
    this.wonGame = this.participants.find((it) => it.win) != undefined;
  }

  protected getParticipantDamageDealtToChampionsShare(match: MatchDto, participant: MatchParticipantDto) {
    if (!participant._tmp.damageDealtToChampionsShare) {
      participant._tmp.damageDealtToChampionsShare =
        (participant.totalDamageDealtToChampions / this.matchHelpers.getMaxTotalDamageDealtToChampions(match)) * 100;
    }
    return participant._tmp.damageDealtToChampionsShare;
  }

  protected getParticipantDamageTakenShare(match: MatchDto, participant: MatchParticipantDto) {
    if (!participant._tmp.damageTakenShare) {
      participant._tmp.damageTakenShare =
        (participant.totalDamageTaken / this.matchHelpers.getMaxTotalDamageTaken(match)) * 100;
    }
    return participant._tmp.damageTakenShare;
  }
}
