import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppHeaderComponent } from './app-header.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [CommonModule, BrowserModule, RouterModule, NgOptimizedImage],
  exports: [AppHeaderComponent],
})
export class AppHeaderModule {}
