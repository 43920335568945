import { Component } from '@angular/core';
import { SnowflakeService } from 'src/services/snowflake.service';

@Component({
  selector: 'app-snowflakes',
  templateUrl: 'app-snowflakes.component.html',
  styleUrls: ['app-snowflakes.component.scss'],
})
export class AppSnowflakesComponent {
  protected luxieIndex = Math.floor(Math.random() * 12);

  constructor(protected snowflakeService: SnowflakeService) {}
}
