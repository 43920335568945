import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LookbackDto } from 'src/dto/LookbackDto';
import { MatchDto } from 'src/dto/MatchDto';
import { duration } from 'src/helpers/time.helpers';
import { MatchParticipantDto } from 'src/dto/MatchParticipantDto';
import { MatchHelpers } from 'src/helpers/match.helpers';
import { LookbackRequestSummonerDto } from 'src/dto/LookbackRequestSummonerDto';

export type RequestLookbackParticipantWithIndex = LookbackRequestSummonerDto & {
  index: number;
};

export type RequestLookbackWithParticipant = {
  summoners: RequestLookbackParticipantWithIndex[];
};

export type LookbackAgainPanelQuestionForSummonerDto = {
  event: MouseEvent;
  summoner: string;
  tag: string;
};

@Component({
  selector: 'app-lookback-match',
  templateUrl: './lookback-match.component.html',
  styleUrls: ['./lookback-match.component.scss'],
  providers: [MatchHelpers],
})
export class LookbackMatchComponent {
  @Input({ required: true })
  public currentLookback: LookbackDto;
  @Input({ required: true })
  public match: MatchDto;
  @Output()
  public openLookbackAgainPanelQuestionForSummoner = new EventEmitter<LookbackAgainPanelQuestionForSummonerDto>();

  constructor(protected matchHelpers: MatchHelpers) {}

  protected getMatchStatusClass(): string {
    if (!this.match._tmp.statusClass) {
      const lookbackedParticipants = this.matchHelpers.getLookbackParticipants(this.currentLookback, this.match);

      if (lookbackedParticipants.length < 2) {
        return (this.match._tmp.statusClass = '');
      }

      if (this.match.remake) {
        return (this.match._tmp.statusClass = 'remake');
      } else if (lookbackedParticipants.every((it) => it.teamId === lookbackedParticipants[0].teamId)) {
        return (this.match._tmp.statusClass = lookbackedParticipants[0].win ? 'victory' : 'defeat');
      } else {
        return (this.match._tmp.statusClass = 'opponents');
      }
    }

    return this.match._tmp.statusClass;
  }

  protected getMatchDuration() {
    if (!this.match._tmp.duration) {
      this.match._tmp.duration = duration(this.match.gameStart, this.match.gameEnd);
    }

    return this.match._tmp.duration;
  }

  protected openRequestDialog(event: Event, participant: MatchParticipantDto) {
    event.preventDefault();

    if (!this.matchHelpers.isLookbackParticipant(this.currentLookback, participant)) {
      this.openLookbackAgainPanelQuestionForSummoner.emit({
        event: event as MouseEvent,
        summoner: participant.riotIdName,
        tag: participant.riotIdTagline,
      });
    }
  }
}
