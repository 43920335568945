import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RegionDto } from 'src/dto/RegionDto';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  constructor(private httpClient: HttpClient) {}

  public getRegions(): Observable<RegionDto[]> {
    return this.httpClient.get<RegionDto[]>(`${environment.baseUrl}/api/regions`);
  }
}
