import { LookbackSummonerDto } from 'src/dto/LookbackSummonerDto';

export class LookbackRequestSummonerDto {
  public name: string;
  public tag?: string;

  public static fromLookbackSummoner(lookbackSummoner: LookbackSummonerDto): LookbackRequestSummonerDto {
    const lookbackRequestSummoner = new LookbackRequestSummonerDto();

    lookbackRequestSummoner.name = lookbackSummoner.name;
    lookbackRequestSummoner.tag = lookbackSummoner.tag;

    return lookbackRequestSummoner;
  }
}
