export function joinListGrammatically(list: string[]): string {
  return list.length > 1
    ? list
        .slice(0, list.length - 1)
        .join(', ')
        .concat(' and ')
        .concat(list[list.length - 1])
    : list[0];
}

export function mapAsStrong(value: string): string {
  return `<strong>${value}</strong>`;
}

export function isNullOrEmpty(value: string): boolean {
  return value === null || value === undefined || value === '';
}
