import { LookbackDto } from 'src/dto/LookbackDto';
import { LookbackRequestSummonerDto } from 'src/dto/LookbackRequestSummonerDto';

export class LookbackRequestDto {
  public summoners: LookbackRequestSummonerDto[];
  public platform: string;
  public queueTypes: string[];

  public static fromLookback(lookback: LookbackDto): LookbackRequestDto {
    const lookbackRequest = new LookbackRequestDto();

    lookbackRequest.summoners = lookback.summoners.map((it) => LookbackRequestSummonerDto.fromLookbackSummoner(it));
    lookbackRequest.platform = lookback.platform;
    lookbackRequest.queueTypes = lookback.queueTypes.split(',');

    return lookbackRequest;
  }
}
