import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { DarkModeToggleComponent } from './dark-mode-toggle.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';

@NgModule({
  declarations: [DarkModeToggleComponent],
  imports: [CommonModule, BrowserModule, NgOptimizedImage],
  exports: [DarkModeToggleComponent],
})
export class DarkModeToggleModule {}
