import { Injectable } from '@angular/core';

const DARK_MODE_KEY = 'lol_lookback_dark_mode';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  constructor() {
    this.updateBodyElement(this.isDarkMode());
  }

  public isDarkMode(): boolean {
    return (localStorage.getItem(DARK_MODE_KEY) ?? 'true') === 'true';
  }

  public setDarkMode(darkMode: boolean): void {
    localStorage.setItem(DARK_MODE_KEY, String(darkMode));

    this.updateBodyElement(darkMode);
  }

  private updateBodyElement(darkMode: boolean): void {
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }
}
