<div class='page-container'>
  <div class='snowflakes-box'>
    <h1>
      <span>Do you wanna see snowflakes?</span>

      <img ngSrc="/assets/media/plink.gif" alt="Cat blinking" height="50" width="50"/>
    </h1>

    <button class='button no' (click)="this.disableSnowflakes()">
      <span>No</span>

      <img ngSrc="/assets/media/stitch_sad.gif" alt="Sad stitch" height="35" width="35"/>
    </button>
    <button class='button yes' (click)="this.enableSnowflakes()">
      <span>OMG Yes please</span>

      <img ngSrc="/assets/media/cute_hopping.gif" alt="Cute animal hopping" height="35" width="35"/>
    </button>
  </div>
</div>
