import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  constructor(private titleService: Title, private metaService: Meta) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Page Not Found - Lookback');
    this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  public ngOnDestroy(): void {
    this.metaService.removeTag('name="robots"');
  }
}
