import swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

export function fireToast(icon: SweetAlertIcon, text: string, duration = 3000): void {
  swal.fire({
    icon: icon,
    title: text,
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: duration,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', swal.stopTimer);
      toast.addEventListener('mouseleave', swal.resumeTimer);
    },
  });
}

export function askConfirmation(title: string, html: string, confirm: () => void) {
  swal
    .fire({
      title: title,
      html: html,
      confirmButtonColor: '#41b882',
      cancelButtonColor: '#ff7674',
      icon: 'error',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    })
    .then(async (response: SweetAlertResult) => {
      if (response.isConfirmed) {
        await confirm();
      }
    });
}
