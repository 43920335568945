import { Component } from '@angular/core';
import { DarkModeService } from 'src/services/dark-mode.service';

@Component({
  selector: 'app-github-icon',
  templateUrl: './github-icon.component.html',
})
export class GithubIconComponent {
  constructor(protected darkModeService: DarkModeService) {}
}
