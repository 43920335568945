import { Component, Input } from '@angular/core';
import { LookbackDto } from 'src/dto/LookbackDto';
import { MatchDto } from 'src/dto/MatchDto';
import { MatchHelpers } from 'src/helpers/match.helpers';
import Team from 'src/dto/Team';

@Component({
  selector: 'app-lookback-match-details',
  templateUrl: './lookback-match-details.component.html',
  styleUrls: ['./lookback-match-details.component.scss'],
  providers: [MatchHelpers],
})
export class LookbackMatchDetailsComponent {
  @Input({ required: true })
  public currentLookback: LookbackDto;
  @Input({ required: true })
  public match: MatchDto;

  constructor(protected matchHelpers: MatchHelpers) {}

  protected readonly Team = Team;
}
