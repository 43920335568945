import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
})
export class SimpleDialogComponent {
  @Input()
  public modal = false;

  @Input({ required: true })
  public visible: boolean;

  @Output()
  public visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('dialog')
  protected dialog: ElementRef;

  @ViewChild('dialogContent')
  protected dialogContent: ElementRef;

  @HostListener('document:keydown', ['$event'])
  public onKeydown(event: KeyboardEvent): void {
    if (this.visible && event.key === 'Escape') {
      this.close();
    }
  }

  @HostListener('document:mousedown', ['$event'])
  public onMouseDown(event: any): void {
    if (this.visible && !this.dialogContent.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  public close(): void {
    this.visible = false;
    this.visibleChange.emit(false);
  }
}
