import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { SnowflakeService } from 'src/services/snowflake.service';
import { fireToast } from 'src/helpers/alert.helpers';

@Component({
  selector: 'app-snowflakes-toggler',
  templateUrl: './snowflakes-toggler.component.html',
  styleUrls: ['./snowflakes-toggler.component.scss'],
})
export class SnowflakesTogglerComponent implements OnInit, OnDestroy {
  constructor(private titleService: Title, private metaService: Meta, private snowflakeService: SnowflakeService) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Toggle snowflakes - Lookback');
    this.metaService.addTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  public ngOnDestroy(): void {
    this.metaService.removeTag('name="robots"');
  }

  protected enableSnowflakes(): void {
    this.snowflakeService.setShouldShowSnowflakes(true);
    fireToast('info', 'You will now be seeing snowflakes on the website!');
  }

  protected disableSnowflakes(): void {
    this.snowflakeService.setShouldShowSnowflakes(false);
    fireToast('info', 'You will now no longer be seeing snowflakes on the website!');
  }
}
