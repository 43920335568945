import { Component } from '@angular/core';
import { DarkModeService } from 'src/services/dark-mode.service';

@Component({
  selector: 'app-discord-icon',
  templateUrl: './discord-icon.component.html',
})
export class DiscordIconComponent {
  constructor(protected darkModeService: DarkModeService) {}
}
