import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HomeComponent } from 'src/components/public/home/home.component';
import { FormsModule } from '@angular/forms';
import { PageNotFoundComponent } from 'src/components/public/page-not-found/page-not-found.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LookbackComponent } from 'src/components/public/lookback/lookback.component';
import { SharedModule } from 'src/components/shared/shared.module';
import { TooltipModule } from 'primeng/tooltip';
import { LookbackMatchComponent } from 'src/components/public/lookback/lookback-match/lookback-match.component';
import { LookbackMatchDetailsComponent } from 'src/components/public/lookback/lookback-match/lookback-match-details/lookback-match-details.component';
import { LookbackMatchDetailsScoreboardComponent } from 'src/components/public/lookback/lookback-match/lookback-match-details/lookback-match-details-scoreboard/lookback-match-details-scoreboard.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { LookbackRequestFormComponent } from 'src/components/public/lookback/lookback-request-form/lookback-request-form.component';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ChangelogComponent } from 'src/components/public/changelog/changelog.component';
import { RouterLink } from '@angular/router';
import { SnowflakesTogglerComponent } from 'src/components/public/snowflakes-toggler/snowflakes-toggler.component';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgSelectModule,
    SharedModule,
    NgOptimizedImage,
    TooltipModule,
    ProgressBarModule,
    ButtonModule,
    DialogModule,
    OverlayPanelModule,
    RouterLink,
    AdsenseModule,
  ],
  declarations: [
    PageNotFoundComponent,
    HomeComponent,
    ChangelogComponent,
    LookbackRequestFormComponent,
    LookbackComponent,
    LookbackMatchComponent,
    LookbackMatchDetailsComponent,
    LookbackMatchDetailsScoreboardComponent,
    SnowflakesTogglerComponent,
  ],
})
export class PublicModule {}
