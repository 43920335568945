import { NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DarkModeToggleModule } from 'src/components/app/dark-mode-toggle/dark-mode-toggle.module';
import { AppHeaderModule } from 'src/components/app/app-header/app-header.module';
import { AppFooterModule } from 'src/components/app/app-footer/app-footer.module';
import { PublicModule } from 'src/components/public/public.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { DarkModeService } from 'src/services/dark-mode.service';
import { AppSnowflakesComponent } from 'src/components/app/app-snowflakes/app-snowflakes.component';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [AppComponent, AppSnowflakesComponent],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AdsenseModule.forRoot({
      adClient: 'ca-pub-7117246388461085',
    }),
    AppRoutingModule,
    AppHeaderModule,
    DarkModeToggleModule,
    AppFooterModule,
    PublicModule,
  ],
  providers: [provideClientHydration(), DarkModeService],
  bootstrap: [AppComponent],
})
export class AppModule {}
