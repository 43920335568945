export function duration(start: string, end: string) {
  const startTime = new Date(start).getTime();
  const endTime = new Date(end).getTime();

  const millisDuration = endTime - startTime;
  let seconds = Math.floor(millisDuration / 1000);
  const minutes = Math.floor(seconds / 60);
  seconds = seconds - minutes * 60;

  return `${prefixWithZero(minutes)}:${prefixWithZero(seconds)}`;
}

function prefixWithZero(number: number): string {
  return number < 10 ? '0' + number : String(number);
}
