import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LookbackService } from 'src/services/lookback.service';
import { LookbackDto } from 'src/dto/LookbackDto';
import JobStatus from 'src/dto/JobStatus';
import { MatchDto } from 'src/dto/MatchDto';
import { LookbackRequestDto } from 'src/dto/LookbackRequestDto';
import { LookbackAgainPanelQuestionForSummonerDto } from 'src/components/public/lookback/lookback-match/lookback-match.component';
import { SimpleDialogComponent } from 'src/components/shared/simple-dialog/simple-dialog.component';
import { joinListGrammatically, mapAsStrong } from 'src/helpers/string.helpers';
import { OverlayPanel } from 'primeng/overlaypanel';
import { LookbackRequestSummonerDto } from 'src/dto/LookbackRequestSummonerDto';
import { SortDirection } from 'src/dto/SortDirection';

@Component({
  selector: 'app-lookback',
  templateUrl: './lookback.component.html',
  styleUrls: ['./lookback.component.scss'],
  providers: [LookbackService],
})
export class LookbackComponent implements OnInit {
  protected currentLookback: LookbackDto;
  protected matches: MatchDto[];
  protected loadingPage = false;
  protected lookbackAgainDialogVisible = false;
  protected lookbackRequest: LookbackRequestDto;
  @ViewChild('dialog')
  protected dialog: SimpleDialogComponent;
  protected lookbackAgainQuestionSummonerName: string | undefined;
  protected lookbackAgainQuestionSummonerTag: string | undefined;
  @ViewChild('lookbackAgainOverlayPanel')
  protected lookbackAgainOverlayPanel: OverlayPanel;
  private lookbackId: string;
  private currentPage = 0;
  private totalPages = 0;
  protected sortDirection = SortDirection.DESC;

  constructor(
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private lookbackService: LookbackService,
  ) {}

  public ngOnInit(): void {
    this.titleService.setTitle('Lookback');

    this.activatedRoute.params.subscribe((params) => {
      this.lookbackId = params['lookbackId'];
      this.fetchLookbackStatus();
    });
  }

  @HostListener('window:scroll', [])
  public onScroll(): void {
    const appHomeElement = document.querySelector('app-lookback');

    if (!appHomeElement || this.loadingPage || !this.matches) {
      return;
    }

    if (
      window.innerHeight + window.scrollY * 1.1 >= (appHomeElement as HTMLElement).offsetHeight &&
      (this.totalPages === 0 || this.currentPage < this.totalPages)
    ) {
      this.fetchMatchHistory(this.currentPage + 1);
    }
  }

  protected getSummonerNames(): string {
    return joinListGrammatically(
      this.currentLookback.summoners.map((it) => mapAsStrong(it.name + (it.tag ? '#' + it.tag : ''))),
    );
  }

  protected isSuccess(): boolean {
    return this.currentLookback.currentStatus === JobStatus.SUCCESS;
  }

  protected isFailed(): boolean {
    return this.currentLookback.currentStatus === JobStatus.FAILED;
  }

  protected showLookbackAgainDialog(index?: number): void {
    this.lookbackRequest = LookbackRequestDto.fromLookback(this.currentLookback);
    this.closeLookbackAgainOverlayPanel();

    if (index && this.lookbackAgainQuestionSummonerName) {
      let currIdx = 0;

      while (currIdx <= index) {
        if (!this.lookbackRequest.summoners[currIdx]) {
          this.lookbackRequest.summoners[currIdx] = new LookbackRequestSummonerDto();
        }
        currIdx++;
      }

      this.lookbackRequest.summoners[index].name = this.lookbackAgainQuestionSummonerName;
      this.lookbackRequest.summoners[index].tag = this.lookbackAgainQuestionSummonerTag;

      this.lookbackAgainQuestionSummonerName = undefined;
      this.lookbackAgainQuestionSummonerTag = undefined;
    }

    this.lookbackAgainDialogVisible = true;
  }

  protected onLookbackRequest(): void {
    this.dialog.close();
  }

  protected openLookbackAgainPanelQuestionForSummoner(questionDto: LookbackAgainPanelQuestionForSummonerDto): void {
    this.closeLookbackAgainOverlayPanel();

    this.lookbackAgainQuestionSummonerName = questionDto.summoner;
    this.lookbackAgainQuestionSummonerTag = questionDto.tag;

    setTimeout(() => {
      this.lookbackAgainOverlayPanel.toggle(questionDto.event);
    }, 200);
  }

  protected toggleSortDirection(): void {
    this.sortDirection = this.sortDirection === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC;
    this.matches = [];
    this.currentPage = 0;
    this.fetchMatchHistory();
  }

  private fetchLookbackStatus(): void {
    this.lookbackService.getLookback(this.lookbackId).subscribe({
      next: (lookbackDto: LookbackDto) => {
        this.currentLookback = lookbackDto;

        if (this.isSuccess()) {
          this.fetchMatchHistory();
        } else if (!this.isFailed()) {
          setTimeout(this.fetchLookbackStatus.bind(this), 2500);
        }
      },
      error: () => {
        setTimeout(this.fetchLookbackStatus.bind(this), 5000);
      },
    });
  }

  private fetchMatchHistory(page = 0): void {
    if (this.loadingPage) {
      return;
    }

    const previousPage = this.currentPage;
    this.currentPage = page;
    this.loadingPage = true;

    this.lookbackService.getLookbackMatches(this.lookbackId, page, 'gameCreation:' + this.sortDirection).subscribe({
      next: (pageDto) => {
        this.totalPages = pageDto.totalPages;
        this.loadingPage = false;

        if (!this.matches) {
          this.matches = [];
        }

        this.matches.push(
          ...pageDto.items.map((match) => {
            match._tmp = {};
            match.participants = match.participants
              .map((participant) => {
                participant._tmp = {};
                return participant;
              })
              .sort((a, b) => a.index - b.index);
            return match;
          }),
        );
      },
      error: () => {
        this.currentPage = previousPage;
        this.loadingPage = false;
      },
    });
  }

  private closeLookbackAgainOverlayPanel() {
    if (this.lookbackAgainOverlayPanel.overlayVisible) {
      this.lookbackAgainOverlayPanel.hide();
    }
  }
}
