<footer class="footer" id="footer">
  <div class="page-container">
    <div class="copyright">
      <span class="made-by">
        Site made with <span class="love">&hearts;</span> by <a href="//endoy.dev" target="_blank">Endoy</a>.
      </span>
      &copy; Copyright 2022 - {{ this.currentYear }}. All Rights Reserved.
    </div>

    <ul class="links">
      <li>
        <a aria-label="Brings you to my website" href="//endoy.dev" target="_blank">
          <img ngSrc="/assets/media/endoy_logo.png" alt="Endoy Logo" class="endoy-logo" height="38" width="38" />
        </a>
      </li>
      <li>
        <a
          aria-label="Brings you to my Donate page"
          href="//www.paypal.com/donate/?hosted_button_id=N5NXN24M932PJ"
          target="_blank"
        >
          <app-paypal-icon />
        </a>
      </li>
      <li>
        <a aria-label="Brings you to my GitHub" href="//github.com/dieterblancke/" target="_blank">
          <app-github-icon />
        </a>
      </li>
      <li>
        <a aria-label="Bring you an invite for my Discord Server" href="https://discord.endoy.dev/" target="_blank">
          <app-discord-icon />
        </a>
      </li>
    </ul>
  </div>
</footer>
