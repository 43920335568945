<table [class]="(wonGame ? 'victory' : 'defeat')">
  <thead>
  <tr>
    <th colspan="3">
      <span [class]="'match-status ' + (wonGame ? 'victory' : 'defeat')">{{ this.wonGame ? 'Victory' : 'Defeat' }}</span>
      <span class="team">({{ team.charAt(0) + team.substring(1).toLowerCase() }} Team)</span>
    </th>
    <th>KDA</th>
    <th class="damage-shares-column">Damage</th>
    <th>Gold</th>
    <th class="cs-column">CS</th>
    <th class="wards-column">Wards</th>
    <th>Items</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let participant of participants" [class]="matchHelpers.isLookbackParticipant(currentLookback, participant) ? 'active' : ''">
    <td class="champion-icon-column">
      <figure class="champion-icon">
        <img [ngSrc]="participant.champion.imageUrl" [alt]="participant.champion.name" height="50" width="50">

        <figcaption>{{ participant.champLevel }}</figcaption>
      </figure>
    </td>

    <td class="summoner-spells">
      <img [ngSrc]="participant.summonerSpell1.imageUrl"
           [alt]="participant.summonerSpell1.summonerId + ' summoner spell image'"
           [pTooltip]='participant.summonerSpell1.name'
           tooltipPosition="top"
           width="23"
           height="23"/>
      <img [ngSrc]="participant.summonerSpell2.imageUrl"
           [alt]="participant.summonerSpell2.summonerId + ' summoner spell image'"
           [pTooltip]='participant.summonerSpell2.name'
           tooltipPosition="top"
           width="23"
           height="23"/>
    </td>
    <td class="summoner-name-column">
      <span class="summoner-name">{{ participant.riotIdName }}</span>
    </td>
    <td class="kda-column">
      <div class="kda">
        <span class="kills">{{ participant.kills }}</span>
        <span class="deaths">{{ participant.deaths }}</span>
        <span class="assists">{{ participant.assists }}</span>

        <span class="participation"
              pTooltip="Kill participation"
              tooltipPosition="top">{{ participant.killParticipation }} %</span>
      </div>

      <span class="kda-ratio">{{ participant.kda }} KDA</span>
    </td>
    <td class="damage-shares-column">
      <div class="damage-shares-container">
        <div pTooltip="Damage given" tooltipPosition="top">
          <span class="damage">{{ participant.totalDamageDealtToChampions.toLocaleString() }}</span>

          <p-progressBar [value]="getParticipantDamageDealtToChampionsShare(match, participant)"
                         [color]="this.darkModeService.isDarkMode() ? 'darkred' : '#d50000'"
                         [showValue]="false"
                         [style]="{ height: '7px', width: '100%', background: darkModeService.isDarkMode() ? '#263238' : '#737373'}"/>
        </div>

        <div pTooltip="Damage taken" tooltipPosition="top">
          <span class="damage">{{ participant.totalDamageTaken.toLocaleString() }}</span>

          <p-progressBar [value]="getParticipantDamageTakenShare(match, participant)"
                         [color]="this.darkModeService.isDarkMode() ? 'gray' : 'lightgray'"
                         [showValue]="false"
                         [style]="{ height: '7px', width: '100%', background: darkModeService.isDarkMode() ? '#263238' : '#737373'}"/>
        </div>
      </div>
    </td>
    <td>{{ participant.goldEarned > 999 ? (participant.goldEarned / 1000).toFixed(1) + 'k' : participant.goldEarned }}</td>
    <td class="cs-column">
      <span class="farm">{{ participant.farm }}</span>
      <span class="farm-per-minute">{{ participant.farmPerMinute }}/m</span>
    </td>
    <td class="wards-column">
      <span
        [pTooltip]="'Control wards: ' + participant.controlWardsBought + '\nWards placed: ' + participant.wardsPlaced + '\nWards killed: ' + participant.wardsKilled + '\nVision score: ' + participant.visionScore">
        {{ participant.controlWardsBought + ' / ' + participant.wardsPlaced + ' / ' + participant.wardsKilled }}
      </span>
    </td>
    <td class="item-column">
      <ul class="items">
        <li *ngFor="let item of matchHelpers.getParticipantItems(participant)">
          <img *ngIf="item.imageUrl"
               [ngSrc]="item.imageUrl"
               [pTooltip]='item.name'
               tooltipPosition="top"
               class="item"
               [alt]="item.id + ' item image'"
               width="25"
               height="25"/>

          <span *ngIf="!item.imageUrl" class="empty-slot"></span>
        </li>
        <li><span class="filler"></span></li>
      </ul>
    </td>
  </tr>
  </tbody>
</table>
