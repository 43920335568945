<header [class]="(isScrolled ? 'header-scrolled ' : '') + 'header fixed-top'" id='header'>
  <div class='content page-container'>
    <a aria-label='Navigates back to the home page.' class='logo' routerLink='/'>

      @if (darkModeService.isDarkMode()) {
        <img ngSrc='/assets/media/logo-dark.webp'
             alt='Lookback logo'
             width='208'
             height='75'
             [priority]='true' />
      } @else {
        <img ngSrc='/assets/media/logo-light.webp'
             alt='Lookback logo'
             width='208'
             height='75'
             [priority]='true' />
      }
    </a>

    <nav class='navbar' id='navbar'>
      <a href='https://discord.endoy.dev' target='_blank' class='discord'>
        Support Discord
      </a>

      <a routerLink='/changelog'>
        Changelog
      </a>

      <a class='donate' href='//www.paypal.com/donate/?hosted_button_id=N5NXN24M932PJ' target='_blank'>
        Donate
      </a>
    </nav>
  </div>
</header>
