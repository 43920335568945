import { MatchParticipantDto } from 'src/dto/MatchParticipantDto';
import { MatchDto } from 'src/dto/MatchDto';
import { Injectable } from '@angular/core';
import { MatchParticipantItemDto } from 'src/dto/MatchParticipantItemDto';
import { LookbackDto } from 'src/dto/LookbackDto';

@Injectable()
export class MatchHelpers {
  public getBlueTeamParticipants(match: MatchDto): MatchParticipantDto[] {
    return match.participants.filter((it) => it.teamId === 100);
  }

  public getRedTeamParticipants(match: MatchDto): MatchParticipantDto[] {
    return match.participants.filter((it) => it.teamId === 200);
  }

  public getMaxTotalDamageDealtToChampions(match: MatchDto): number {
    return Math.max(...match.participants.map((it) => it.totalDamageDealtToChampions));
  }

  public getMaxTotalDamageTaken(match: MatchDto): number {
    return Math.max(...match.participants.map((it) => it.totalDamageTaken));
  }

  public getParticipantItems(participant: MatchParticipantDto): MatchParticipantItemDto[] {
    return participant.items.sort((a, b) => a.index - b.index);
  }

  public getLookbackParticipants(lookback: LookbackDto, match: MatchDto): MatchParticipantDto[] {
    if (!match._tmp.lookbackParticipants) {
      match._tmp.lookbackParticipants = match.participants
        .filter((participant) => this.isLookbackParticipant(lookback, participant))
        .sort((a, b) => {
          const aIndex = lookback.summoners.findIndex((it) => it.puuid === a.puuid);
          const bIndex = lookback.summoners.findIndex((it) => it.puuid === b.puuid);

          return aIndex - bIndex;
        });
    }

    return match._tmp.lookbackParticipants;
  }

  public isLookbackParticipant(lookback: LookbackDto, participant: MatchParticipantDto): boolean {
    return lookback.summoners.find((it) => it.puuid === participant.puuid) !== undefined;
  }

  public getParticipantRiotId(participant: MatchParticipantDto): string {
    return participant.riotIdName + (participant.riotIdTagline ? `#${participant.riotIdTagline}` : '');
  }
}
