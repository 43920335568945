import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/components/public/home/home.component';
import { PageNotFoundComponent } from 'src/components/public/page-not-found/page-not-found.component';
import { LookbackComponent } from 'src/components/public/lookback/lookback.component';
import { ChangelogComponent } from 'src/components/public/changelog/changelog.component';
import { SnowflakesTogglerComponent } from 'src/components/public/snowflakes-toggler/snowflakes-toggler.component';

const routes: Route[] = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'lookbacks/:lookbackId',
    component: LookbackComponent,
  },
  {
    path: 'changelog',
    component: ChangelogComponent,
  },
  {
    path: 'snowflakes-toggler',
    component: SnowflakesTogglerComponent,
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
