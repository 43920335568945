import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { PaypalIconComponent } from 'src/components/app/app-footer/icons/paypal/paypal-icon.component';
import { DiscordIconComponent } from 'src/components/app/app-footer/icons/discord/discord-icon.component';
import { GithubIconComponent } from 'src/components/app/app-footer/icons/github/github-icon.component';

@NgModule({
  imports: [CommonModule, BrowserModule],
  declarations: [PaypalIconComponent, DiscordIconComponent, GithubIconComponent],
  exports: [PaypalIconComponent, DiscordIconComponent, GithubIconComponent],
})
export class IconsModule {}
