import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LookbackRequestDto } from 'src/dto/LookbackRequestDto';
import { LookbackDto } from 'src/dto/LookbackDto';
import { InitiateLookbackResponseDto } from 'src/dto/InitiateLookbackResponseDto';
import { PageDto } from 'src/dto/PageDto';
import { MatchDto } from 'src/dto/MatchDto';

@Injectable({
  providedIn: 'root',
})
export class LookbackService {
  constructor(private httpClient: HttpClient) {}

  public initiateLookback(lookbackRequestDto: LookbackRequestDto): Observable<InitiateLookbackResponseDto> {
    return this.httpClient.post<InitiateLookbackResponseDto>(`${environment.baseUrl}/api/lookback`, lookbackRequestDto);
  }

  public getLookback(lookbackId: string): Observable<LookbackDto> {
    return this.httpClient.get<LookbackDto>(
      `${environment.baseUrl}/api/lookback/${lookbackId}?uid=` + new Date().getTime(),
    );
  }

  public getLookbackMatches(lookbackId: string, page: number, sort: string = ''): Observable<PageDto<MatchDto>> {
    return this.httpClient.get<PageDto<any>>(`${environment.baseUrl}/api/lookback/${lookbackId}/matches`, {
      params: new HttpParams().set('page', page).set('pageSize', 10).set('sort', sort),
    });
  }
}
