import { Component, OnInit } from '@angular/core';
import { ChangelogDto } from 'src/dto/ChangelogDto';
import { ChangelogService } from 'src/services/changelog.service';

@Component({
  selector: 'app-changelog',
  templateUrl: 'changelog.component.html',
  styleUrls: ['changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  protected changelogs: ChangelogDto[] = [];

  constructor(private changelogService: ChangelogService) {}

  public ngOnInit(): void {
    this.changelogService.getChangelog().subscribe((changelogs) => {
      this.changelogs = changelogs;
    });
  }
}
