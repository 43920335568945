import { Injectable } from '@angular/core';

const SNOWFLAKES_KEY = 'lol_should_show_snowflakes';

@Injectable({
  providedIn: 'root',
})
export class SnowflakeService {
  private showSnowflakes = false;

  constructor() {
    this.showSnowflakes =
      (localStorage.getItem(SNOWFLAKES_KEY) ?? 'false') === 'true' ||
      new Date().getMonth() == 0 ||
      new Date().getMonth() >= 10;
  }

  public shouldShowSnowflakes(): boolean {
    return this.showSnowflakes;
  }

  public setShouldShowSnowflakes(shouldShowSnowflakes: boolean): void {
    if (shouldShowSnowflakes) {
      localStorage.setItem(SNOWFLAKES_KEY, String(shouldShowSnowflakes));
    } else {
      localStorage.removeItem(SNOWFLAKES_KEY);
    }
    this.showSnowflakes = shouldShowSnowflakes;
  }
}
