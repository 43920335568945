<div class='page-container'>
  <p>Page Not Found</p>
  <h1>404</h1>

  <div class='guide-back'>
    <img ngSrc='/assets/media/pandas.svg'
         alt='pandas'
         class='pandas'
         height='300'
         width='300'
         loading='eager' />

    But don't worry! Let these fearless pandas guide you back to safety!

    <a routerLink='/' class='home button'>Go back home</a>
  </div>
</div>
