<div class='changelog page-container'>
  <h2>Changelog</h2>

  @for (changelog of changelogs; track changelog) {
    <div class='changes'>
      <h3>Changes on <strong>{{ changelog.date }}</strong>:</h3>

      <ul>
        @for (change of changelog.changes; track change) {
          <li>{{ change }}</li>
        }
      </ul>
    </div>
  }
</div>
