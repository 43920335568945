<svg aria-label="PayPal" height="40" role="img" viewBox="0 0 512 512" width="40" xmlns="http://www.w3.org/2000/svg">
  <path d="M377 184.8L180.7 399h-72c-5 0-9-5-8-10l48-304c1-7 7-12 14-12h122c84 3 107 46 92 112z" fill="#002c8a" />
  <path
    d="M380.2 165c30 16 37 46 27 86-13 59-52 84-109 85l-16 1c-6 0-10 4-11 10l-13 79c-1 7-7 12-14 12h-60c-5 0-9-5-8-10l22-143c1-5 182-120 182-120z"
    fill="#009be1"
  />
  <path
    d="M197 292l20-127a14 14 0 0 1 13-11h96c23 0 40 4 54 11-5 44-26 115-128 117h-44c-5 0-10 4-11 10z"
    fill="#001f6b"
  />
</svg>
